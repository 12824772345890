import { FaGraduationCap } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { BsTools } from "react-icons/bs";
import { Link } from "react-router-dom";

const HomePage = ( props: any ) => {
   

    return (
        <>
        <span id="homepage_name">
            <h1>Billy Ndegwah Micah</h1>
        </span>
        <hr></hr>
        <div>
            <img id="homepage_image" src={require("../../Data/Images/billyMicah.JPG")} 
            alt={"billy micah at a kangaroo zoo"} />
            <div id="iconHolder">
                
                <span className="icons">
                    <Link to="/education" > 
                        <FaGraduationCap className="reactIcons" size={ 30 } color={'white'} />
                    </Link>
                </span> 
                
                <span className="icons"> 
                    <Link to="/experience" >
                        <MdWork className="reactIcons" size={ 30 } color={'white'} /> 
                    </Link>
                </span> 
                
                <span className="icons">
                    <Link to="/skills" >
                        <BsTools className="reactIcons" size={ 30 } color={'white'} />
                    </Link>
                </span> 
            </div>
            <div id="aboutMeText">
                <p> <a className="external_link" href="https://learn.billymicah.com"> **Join My Classes &lt; Kids -&gt; Adults &gt;** </a> </p>
                <p>
                    I am a current Student of Robotics &amp; Mechatronics/Computer Science
                    at the Swinburne University of Technology. I love technology and research 
                    and this led me to found a research club <em>(Tech Centric Swinbees Research Club,
                    founded on January 2022)</em> just recently at my current University where 
                    we seek to narrow down the gap between University and industry by involving students 
                    in University-Industry Collaboration. Our club is aimed at strategically 
                    engaging in developmental research to be able to provide local businesses
                    with a technological solution to the problem statement. This nurture students' 
                    interest in research and further studies.
                </p>
                <br></br>
                <p> I do enjoy teaching, and I find having to break down concepts in a bid 
                    to help others understand actually does improve my own understanding of 
                    the concepts, especially when students ask questions I haven't asked before 
                    which leads me to some very fruitful research. Students can also inspire my 
                    creativity and be a fuel for curiosity.
                </p>
                <br></br> 
                <p>
                    I currently teach <a className="external_link" href="https://learn.billymicah.com/course_pathways">Software Development</a>, 
                    <a className="external_link" href="https://learn.billymicah.com/course_pathways"> Web Development</a>, and
                    <a className="external_link" href="https://learn.billymicah.com/course_pathways"> AI/Machine Learning </a> 
                    through my online <a className="external_link" href="https://learn.billymicah.com">1-on-1 classes. </a> 
                    I am confidently competent in Flask, Django, and the MERN (Mongo, Express React Nodejs)
                    stacks, along with using some common AI algorithms (i.e. Expectation Maximization, Canny Edge Detection algorithms in Python).
                    My personal favorite languages are C++, Python, and TypeScript.
                </p>
            </div>
        </div>
        
        
        
        {/* 
        TODO: add this feature later on.
        <form>
            <label htmlFor="visitorName"> And your name is: </label><br/>
            <input id="visitorName" name="visitorName" type="text" placeholder="Enter your name"
            value={ visitorName } onChange={(e) => setVisitorName(e.target.value)  }  ></input><br/>
            
            <label htmlFor="social" > We can connect on: </label><br/>
            <select id="social" name="social" 
            onChange={ (e) => setVisitorSocial(e.target.value) } >
                <option value="LinkedIn" >LinkedIn</option>
                <option value="Twitter" >Twitter</option>
                <option value="Github" >Github</option>
                <option value="Medium" >Medium</option>
            </select><br/>
            
            <label htmlFor="socialUrl" > Your { (visitorSocial !== null) ? visitorSocial : "" } url: </label><br/>
            <input id="socialUrl" name="socialUrl" type="text" /><br/>
            <button onClick={ (e) =>
            getName(e)
            }>Let Me In</button>
            <button onClick={ (e) => getName(e) } >Cancel</button><br/>
        </form> */}
        
        </>
    )
}

export default HomePage;