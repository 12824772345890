import React, { useState, useEffect } from "react";
import { FormInput, LeftContainer } from "../../StyledComponents/StyledComponents";

const Meet = ()=> {
    const [ name, setName ] = useState("");
    const [ nameErr, setNameErr ] = useState("");

    const [ meet, setMeet ] = useState("");

    const [ email, setEmail ] = useState("");
    const [ emailErr, setEmailErr ] = useState("");

    const [ company, setCompany ] = useState("");
    const [ companyErr, setCompanyErr ] = useState("");

    // Error Messages
    function submission(e: React.MouseEvent){
        e.preventDefault();

        const nameEntry: HTMLElement | null = document.getElementById("nameErr");
        const emailEntry: HTMLElement | null = document.getElementById("emailErr");
        const companyEntry: HTMLElement | null = document.getElementById("companyErr");

        
        if (nameEntry){
            if (name === ""){
                setNameErr("Name cannot be left empty");
            } else if (name.length > 30 || name.length < 3){
                setNameErr("Name length couldn't be accepted.");
            } else if (name.includes('/0-9/')){
                setNameErr("Name not expected to have numbers.");
            }
        }

        if (emailEntry){
            if (email === ""){
                setEmailErr("Email cannot be left empty");
            } else if (email.length > 50 || email.length < 5){
                setEmailErr("Email length not adhering to acceptable standard");
            } else if (email.includes('/0-9/')){
                setEmailErr("Email not adhering to acceptable format");
            }
        }

        if (companyEntry){
            if(company === ""){
                setCompanyErr("Company Url cannot be left empty");
            } else if (company.length < 5){
                setCompanyErr("Company Url length not adhering to acceptable standard");
            } else if (company.includes('/0-9/')){
                setCompanyErr("Company Url not adhering to acceptable format");
            }
        }

    }
    

    return (
        <>
        <h1>Wanna arrange to Meet?</h1>
        <div className="meetDiv">
            <LeftContainer>
            <form className="meetForm" method="post">
                <div className="form-group">
                    <label className="meetForm" htmlFor="meet" >Select Meeting Platform</label>
                    <select className="meetOption form-control" id="meet" name="meet" value={ meet } 
                    onChange={ (e) => { 
                        setMeet(e.target.value) 
                    }} >
                        <option value="zoom" >Zoom</option>
                        <option value="call" >Phone Call</option>
                        <option value="googleMeet" >Google Meet</option>
                        <option value="msTeams" >MS Teams</option>
                    </select><br/>
                    <p id="meetErr" className="errorMessage" ></p><br/>
                </div>
                <div className="form-group">
                    <label className="meetForm" htmlFor="name" >Name*</label><br/>
                    <FormInput id="name" name="name" value={ name } 
                    onChange={ (e) => {
                        setName(e.target.value)
                        setNameErr("")
                    }} /><br/>
                    <p id="nameErr" className="errorMessage" >{ nameErr }</p><br/>
                </div>
                <div className="form-group">
                    <label className="meetForm" htmlFor="email" >Email*</label><br/>
                    <FormInput id="email" name="email" type="email" value={ email } 
                    onChange={ (e) => {
                        setEmail(e.target.value) 
                        setEmailErr("")
                    }} /><br/>
                    <p id="emailErr" className="errorMessage" >{ emailErr }</p><br/>
                </div>
                <div className="form-group">
                    <label className="meetForm" htmlFor="company" >Company Url*</label><br/>
                    <FormInput id="email" name="company" value={ company } 
                    onChange={ (e) => {
                        setCompany(e.target.value)  
                        setCompanyErr("")
                    }} /><br/>
                    <p id="companyErr" className="errorMessage" >{ companyErr }</p><br/>
                </div>
                <div className="form-group">
                    <label className="meetCalendly"> 
                        <a href="https://calendly.com/billymicah/meetup">Appointment Date</a> 
                    </label><br/>
                </div>

                
                <br/>
                <button id="meetSend" onClick={ (e) => {
                    submission(e)
                    // console.log(`Meet: ${ meet }`);
                    // console.log(`Name: ${ name }`);
                    // console.log(`Email: ${ email }`);
                    // console.log(`Company Url: ${ company }`);
                }} >Send</button>
                <button id="meetClear" >Clear</button>
            </form>
            </LeftContainer>
        </div>
        
        
        </>
    )
}

export default Meet;