import styled from "styled-components";

// USED ON THE NAVIGATION BAR
export const NavBar = styled.div`
    width: 96vw;
    display: flex;
    justify-content: space-around;
    padding: 10px;
`;

//////////////////////////////////////////////////////////

// USED ON THE EXPERIENCE PAGE
export const MinimalCard = styled.span`
    background-color: lightpink;
    border-radius: 35px;
    height:500px;
    z-index: 3;

`;

export const DescriptionCard = styled.div`
    height: 70vh;
    border-radius: 20px;
    width: 
`;
///////////////////////////////////////////////////////////

// USED GLOBALLY ON HOMEPAGE, SKILLS PAGE, PAGE NOT FOUND, ETC.
export const MainScreen = styled.div`
    height: 87vh;
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 600px){
        display: block;
    }
    
`;

export const Centered = styled.div`
    height: 100%;
    width: 55%;
    @media screen and (max-width: 600px){
        width: 100vw;
        img {
            border-radius: 0;
            position: -web-sticky;
        }
    }
`;
////////////////////////////////////////////////////////////

// ON THE MEET PAGE
export const MainContainer = styled.div`
    height: 80vh;
    display: flex;
    justify-content: center;
    background: url("../Data/Images/billyMicah.JPG");
`;
export const LeftContainer = styled.div`
    width: 250px;
    height: 70vh;
    font-size: 1.0em;
`;
// export const RightContainer = styled.div`
//     background: transparent
//     width: 250px;
//     height: 70vh;
//     border: 2px solid black;
// `;
export const FormInput = styled.input.attrs((props: any) => ({
    type: props.type ? props.type : "text",
    size: props.medium ? 8 : 10,
}))`
    width: 150px;
    height: 30px;
    border-radius: 10px;
    outline: 0;
    border: 0;
    font-size: 1.0em;
`;
/////////////////////////////////////////////////////////////
// ON THE SKILL PAGE

export const SkillSwipe = styled.span`
    border-radius: 35px;
    box-shadow: 0px 40px 20px -20px rgba(255, 182, 193, 0.123);
    min-height: 450px;
    height: 55vh;
    width: 300px;
    margin-top: 20px;
    position:absolute;
    @media screen and (max-width: 360px){
        height: 250px;
        min-height: 250px;
        width: 180px;
        position: absolute;
        margin: 10px;
    }
`;


///////////////////////////////////////////////////////////////