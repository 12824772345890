import { SkillInterface } from "../PageInterfaces/Interface";

export const Skills = ( { tools }: { tools: SkillInterface[] } ) => {

    return(
        <>
        <h1 >Check out some of my projects</h1>
        <div className="skills_outer_div"> 
        {
            tools.map( (item, index) => {
                return (
                <div key={ index } id={ item.tag } className="skillCard card w-96 bg-neutral shadow-xl image-full">
                    <span className="skillImage">
                        {/* Add project images then attach them here */}
                        {/* <figure><img className="skillProjectImage" src={(item.image)?.toString()} alt="Github Project" /></figure> */}
                        <figure><img className="skillProjectImage" src="https://placeimg.com/400/225/arch" alt="Github Project" /></figure>
                    </span>
                    <div className="skillItemsContainer card-body">
                        <h2 className="card-title">{ item.skill }</h2>
                        <hr></hr>
                        <p>Demonstrative Projects: <br></br>
                        { item.projects } </p>
                        <div className="card-actions justify-end">
                        <a  href={ (item.github)?.toString() } target="_blank" rel="noreferrer"><button className="btn btn-primary"> Github </button></a>
                        </div>
                    </div>
                </div>
                )
            })
        }
        </div>
        </>
    )
}
    
export default Skills;