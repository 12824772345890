import { EducationInterface, SkillInterface, WorkExperience } from "../pages/PageInterfaces/Interface";

export const EduInstitution: EducationInterface[] = [
    {
    name: "Swinburne University of Technology",
    address: " Q5B, 93350 Kuching, Sarawak, Malaysia",
    level: "Bachelors Degree",
    course: "Robotics and Mechatronics (Honours)",
    graduation: 2025,
    present: true
  },
  {
    name: "Swinburne University of Technology",
    address: " Q5B, 93350 Kuching, Sarawak, Malaysia",
    level: "Bachelors Degree",
    course: "Computer Science",
    graduation: 2025,
    present: true
  },
  {
    name: "Taylor's College",
    address: "952 Bourke Street Waterloo NSW, Australia",
    level: "Foundations Program",
    course: "Foundations of Science for Engineering",
    graduation: 2016
  },
  {
    name: "Makueni Boys' High School",
    address: "Wote Town Makueni, Kenya",
    level: "High School",
    course: "High School KCSE level Subject",
    graduation: 2014
  }
  
]

export const Roles: WorkExperience[] = [
    {
        country:"HongKong",
        city:"Causeway Bay",
        companyName: "Preface Education",
        employment: "Part time",
        role: "Programming & STEM Instructor",
        startDate: "August 2022",
        present: true,
        endDate: "-",
        description: [
            "Train student on concepts and implementation, within good practice, of web development and software development application cases.",
            "Provide class projects in written code for courses along with notes for instructors to use in teaching.",
            "Assist Instructors during classes, from handling server errors, to code updates for a smooth running of the class.",
            "Offer parents advise on what pathways best suit their child's interests (if below 18yrs)/advice students on what pathway to take based on goals (above 18yrs)",
            "Issue feedback to parent on students' strengths, class performance and progress in their chosen subjects."
            
        ]
    },
    {
        country:"Virtual",
        city:"Remote",
        companyName: "Billy M Tutoring",
        employment: "Part time",
        role:"Programming & STEM Tutor",
        startDate: "January 2021",
        present: true,
        endDate: "-",
        description: [
            "Provide class material for students in different subjects (where needed)",
            "Guide students through hurdles in their chosen subjects (from ones stemming from the method of approach to ones due to missing foundational understanding).",
            "Provide assignments to challenge students' understanding in topics covered during class",
            "Identify student strengths and weaknesses and adjust method of delivery to suit their needs and help improve on their weaknesses while keeping their strength strong.",
            "Advice parents on what projects best suit their children (if below 18yrs)/advice students on what projects to take (above 18yrs)",
            "Provide feedback on students' progress in their chosen subjects."
        ]
        
    },
    {
        country:"HongKong",
        city:"Kowloon",
        companyName: "First Code Academy",
        employment: "Part time",
        role: "Coding & STEM Instructor",
        startDate: "July 2021",
        present: false,
        endDate: "May 2022",
        description: [
            "Teach web development and software development concepts by means of guided projects.",
            "Provide class projects in written code for courses along with notes for instructors to use in teaching.",
            "Provide Instructor assistance during classes, from handling server errors, to code updates for a smooth running of the class.",
            "Conduct instructor interviews and providing written feedback according to the company metrics and criteria.",
            "Managing the database linking, debugging class projects and providing written guidelines on handling intergration errors in cases where students used a different OS from the one the project was programmed in.",
            "Providing feedback on the student's progress, and encouraging a creative outlook toward programming by suggesting code edits (by student) on class projects."
        ]
    },
    {
        country:"Kenya",
        city:"Nairobi",
        companyName: "Evib Sports Science Gym",
        employment: "Full time",
        role:"Membership Consultant",
        startDate: "February 2020",
        present: false,
        endDate: "November 2020",
        description: [
            "I helped new subscribers looking to make lifestyle transformations break into new habits smoothly; using an analysis index to determine their current behavioral patterns etc",
            "Increased monthly income by 15% within the first 4 months by analyzing and improving space quality, class design and member retention rate.",
            "Proposed a renovation of the facility (after comparing the standard to similar surrounding facilities) which helped stand out from competition.",
            "Improved business' online presence by engaging clients to give reviews and include photo tags.",
            "Self-trained in using member retention prediction indices and used it to increase member retention rate (Identifying areas that could affect initial client experiences positively and negatively)",
            "Helped instill company culture and core values in employees."
        ]
    },
    {
        country:"Australia",
        city:"Sydney",
        companyName: "DLC Recruitment",
        employment: "Full time",
        role:"Site Assistant",
        startDate: "August 2017",
        present: false,
        endDate: "2020 January",
        description: [
            "Reported to the delivery department [Miele] on what appliances needed to be delivered to the site.",
            "Managed appliance distribution on site taking recorded on damages and completed work along with incomplete work",
            "Provided time sheets for different laborers and managed their runs during their shift.",
            "Assigned team members to different buildings for appliance distribution",
            "Informed the main office on the number of workers needed on site on specific days depending on instruction from the Site Project manager",
            "Recorded the start and end times of team members on site."
        ]
    },
    {
        country: "Australia",
        city:"Sydney",
        companyName: "O'Connor Marketing",
        employment: "Full time",
        role: "Sales Consultant Team Lead",
        startDate: "June 2016",
        present: false,
        endDate: "September 2017",
        description: [
            "I offered training to beginning sales consultants taking them to the field with me and demonstrating the practice to them.",
            "I managed teams in conducting sales for Amnesty, Oxfam and Alannah and Madeline foundation.",
            "Helped instill company culture in trainees (Motivating them to trust the system handed to us to use).",
            "Traced the progress of my team to identify their strengths and weaknesses for effective mentoring purposes.",
            "Learned the best way to lead is by example (since most of those you train end up copying what you do).",
            "Gave talks on effective field strategies during morning meetings and helped the team in writing our day break downs."
        ]
    }
]

export const Tools: SkillInterface[] = [
    {
        tag: "softwareDev",
        skill: "Software Development",
        image: "https://github.com/Billy-06/",
        tools: [
            "C++", "Python3"
        ],
        projects: [
            "Voting Application C++, SQLite3",
            "Design Patterns Project",
            "Algorithm Explore Project"
        ],
        github: "https://github.com/Billy-06/CSharp_Fun_Programming"
    },
    {
        tag: "webDev",
        skill: "Full Stack Web Development",
        image: "https://github.com/Billy-06/",
        tools: [
            "MERN Stack (MongoDB, ExpressJs, ReactJs, NodeJs)",
            "Django", "Flask", "PHP, SQL, JavaScript, HTML, CSS"
        ],
        projects: [
            "Personal Website",
            "Django Portfolio",
            "Flask Project"
        ],
        github: "https://github.com/Billy-06/"
    },
    {
        tag: "machineLearn",
        skill: "Machine Learning",
        image: "../../public/model_training.png",
        tools: [
            "Python, Pandas, TensorFlow, SciKit-Learn",
            "Distributed Training (multiple GPUs)",
            "Designing Custom Loss Function"
        ],
        projects: [
            "Python Models Project"
        ],
        github: "https://github.com/Billy-06/Personal_CompVis_Projects"
    },
    {
        tag: "dataVisual",
        skill: "Data Visualization",
        image: "../../public/data_viz.png",
        tools: [
            "Python, Matplotlib, Seaborn, Pandas, SciKit-Learn"
        ],
        projects: [
            "Python Models Project"
        ],
        github: "https://github.com/Billy-06/Personal_CompVis_Projects"
    },
    {
        tag: "compVision",
        skill: "Computer Vision",
        image: "../../public/eigenfaces.png",
        tools: [
            "Python, OpenCV, Tensorflow, Numpy, Classification Models",
            "C++, OpenCV, Classification Models, Tensorflow"
        ],
        projects: [
            "Python Models Project",
            "Dental Assistant Project"
        ],
        github: "https://github.com/Billy-06/Personal_CompVis_Projects"
    },
    {
        tag: "linuxSystem",
        skill: "Linux",
        image: "https://github.com/Billy-06/",
        tools: [
            "Debian", "Kali Linux", "Ubuntu"
        ],
        projects:[
            "Learning Docker"
        ],
        github: "https://github.com/Billy-06/"
    },
]
