import { Link } from "react-router-dom";
import { NavBar } from "../../StyledComponents/StyledComponents";
import { FaVideo } from "react-icons/fa";

const Header = () => {
    return (
        <>
        <NavBar>
            <button type="button" className="btn btn-secondary btn-lg"> <Link className="headerItem" to="/" >Billy</Link> </button>
            <a href="https://calendly.com/billymicah/meetup"><button type="button" className="btn btn-secondary btn-lg"><FaVideo className="reactIcons" size={30} /> </button></a>
            <button type="button" className="btn btn-secondary btn-lg"> <Link className="headerItem" to="/hobbies" >More</Link> </button>
        </NavBar>
        
        </>
    )
}

export default Header;