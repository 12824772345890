import React from 'react';
import './App.css';
import { Routes, Route, HashRouter } from "react-router-dom";
// import { BrowserRouter as Router } from "react-router-dom";
import Education from "./pages/components/Education";
import { ExperienceDetails} from "./pages/components/Experience";
import HobbiesFun from "./pages/components/HobbiesFun";
import HomePage from "./pages/components/HomePage";
import Meet from "./pages/components/Meet";
import { Skills } from "./pages/components/Skills";
// import Welcome from "./pages/components/Welcome";
import NotFound from "./pages/includes/NotFound";
import Header from "./pages/includes/Header";
// import Footer from "./pages/includes/Footer";
// import PieChart from "./pages/PieChart";
// import { SplitScreen } from './pages/SplitScreen';
import { Centered, MainScreen } from './StyledComponents/StyledComponents';
import { EduInstitution, Roles, Tools } from './Data/Data';

// const childList = [
//   <Experience work={ Roles  } />,
//   <ExperienceDetails work={ Roles }/>
// ]

class App extends React.Component {
  
  render(){
    return (
      <>
        <div className="App"> 
        <HashRouter>
          <Header />
          <div className="displayPage" >
            <Routes>
              { /** TODO: Make the HomePage (the initial page seen on page load),
               * a Modal whereby, when the user provides their social detals and url
               * (and also after confirming that these are correct and exist), then
               * disable the modal to allow a more free navigation around the website. 
               * 
               * For Users/visitors who don't provide their information allow for certain
               * views (i.e. Skills, Experience and HomePage) but limit acces to the others
               * while mainitaining a mildly opaque background to entice curiosity. */ } 
              <Route path="" element={
                <MainScreen>
                  <Centered>
                     <HomePage /> 
                  </Centered>
                </MainScreen>
              } ></Route>
              <Route path="/education" element={
                <MainScreen>
                  <Centered>
                    <Education myEdu={ EduInstitution }/>
                  </Centered>
                </MainScreen>
              } ></Route>
              {/* TODO: On the experiences page, split screen in order to show
              the minimalistic display on the left pane of the screen and 
              the explanatory view on the right hand pane of the screen. Attempt
              utilising props in order to iterate thorough each one while displaying
              to allow for more control on where top place it and the behaviour also 
              
              // <SplitScreen { ...childList } leftWeight={ 1 } rightWeight={ 3 } >
                  
              // </SplitScreen>
              */}
              <Route path="/experience" element={
                <MainScreen>
                  <Centered>
                    <ExperienceDetails work={ Roles }/>
                  </Centered>
                </MainScreen>
                
              } ></Route>

              {/** TODO: Now that the cards are shaped up and placed correcly, introduce
               * animation and transitions to them to make them much more smooth and
               * natural. Have the right swipes trasitions into hearts and the left swipes
               * transition into an 'X' (ideally, however if it proves a pain go for the 
               * average card tilts to the direction of the swipe.)
               */}
              <Route path="/skills" element={
                <MainScreen>
                  <Centered>
                    <Skills tools={ Tools } />
                  </Centered>
                </MainScreen>
              } ></Route>
              {/* 
              TODO: work on this later on
              <Route path="/welcome" element={
                <PieChart { ...Welcome } >

                </PieChart>
              } ></Route> */}
              <Route path="/hobbies" element={ <HobbiesFun /> } ></Route>
              <Route path="/meet" element={ 
                <MainScreen>
                  <Centered>
                    <Meet /> 
                  </Centered>
                </MainScreen>
              } ></Route>
              {/** Using the asterisk as a catchall for any unavailable urls requests
               * which is in turn linked to the PageNotFound component
               */}
              <Route path="*" element={ <NotFound /> } ></Route>
            </Routes>
          </div>
        </HashRouter>
        </div>
      </>
      
    );
}}

export default App;
