import React from "react";

const NotFound = () => {
    return (
        <>
        <h1>Page Not Found</h1>
        <img id="puzzledFace" src={ require("../../Data/Images/billyPuzzled.JPG") }
            alt={ "billy wearing a puzzled face" }/>
        </>
    )
}

export default NotFound;