import { WorkExperience } from "../PageInterfaces/Interface";
import { MinimalCard } from "../../StyledComponents/StyledComponents";





export const Experience 
= ({ work }: { work: WorkExperience[]} ): JSX.Element => {

    return(
        <>
        { work.map( (item, id) => 
        <MinimalCard key={ id }>
            <h2>Role: { item.role }</h2>
            <h3>Company: { item.companyName }</h3>
        </MinimalCard>) }

        </>
    )
}

export const ExperienceDetails
= ({ work }: { work: WorkExperience[] }) => {
    

    return(
        <>
        { work.map((item, id) => 
         <div className="workRole card w-96 bg-base-100 shadow-xl">
            <span className="roleName">
                <h2 className="card-title">{ item.role } at { item.companyName }</h2>
            </span>
                <hr></hr>
            <div className="statusContainer card-body">
                <p className="workStatus">
                    <strong><em> { item.city }, { item.country } </em><br></br>
                    Started on:{ ` ${item.startDate}` }<br></br> Ended on: 
                    { (item.endDate === "-")? " Currently at position" : ` ${item.endDate}` }</strong>
                </p>
            </div>
            <div className="dutyContainer"><ul>{ item.description.map( (duty, id) => <li className="workDutyListVisible" key={ id } > &gt; { duty }</li> ) }</ul></div>
            <div className="linkedin_connect card-actions justify-end">
            <a href="https://www.linkedin.com/in/billy-micah-875142b4/"><button className="btn btn-primary">LinkedIn</button></a>
            </div>
        </div>
        ) }
        </>
    )
}
