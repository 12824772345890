import { EducationInterface } from "../PageInterfaces/Interface";

const Education = ({ myEdu }:{ myEdu: EducationInterface[] }) => {

    return (
        <>
        {
          myEdu.map((item, id) => {
            return(
              <>
              <div key={ id } className="eduContainer h-96 carousel carousel-vertical rounded-box">
                  <div className="eduSubcontainer card w-96 text-primary-content">
                      <span className="institution_info">
                        <h4 className="card-title">{ item.name }</h4>
                        <h5>{ item.address }</h5>
                      </span>
                      <hr></hr>
                 </div> 
                    <div className=" card-body">
                      <p className="educationDetails">
                        Level: <strong><em> { item.level } </em></strong> <br></br>
                        Name:  <strong><em> { item.course } </em></strong> <br></br>
                      
                        { (item.graduation >= 2022) 
                        ? "Expected Graduation Year:"
                        : "Graduation Year:"} { item.graduation }
                      </p>
                   </div>
              </div>
              
              </>
            );
          })
        }
        </>
    );
}

export default Education;