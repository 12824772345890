const HobbiesFun = () => {
    return(
        <>
        <p>I'm working on the face swap model, can't wait to
            try it with you next time. Comming Soon</p>
        <button>Let's Face Swap </button>
        </>
    )
}

export default HobbiesFun;